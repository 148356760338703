<mat-toolbar color="primary">
	<mat-toolbar-row>
		<span>GreenCommerce Connector - Beheerportal ({{ environmentName }})</span>

		@if (authService.isLoggedIn) {
			<span class="toolbar-nav-buttons hide-sm">
				<a mat-button routerLink="/organisations">Organisaties</a>
				<a mat-button routerLink="/applications">Applicaties</a>
			</span>
			<span class="toolbar-spacer"></span>
			<span class="hide-sm">
				<button mat-button (click)="onLogout()">Uitloggen</button>
			</span>
			<button mat-icon-button [matMenuTriggerFor]="toolbarMenu" class="show-sm">
				<mat-icon>menu</mat-icon>
			</button>
		} @else {
			<span class="toolbar-spacer"></span>
		}
		<mat-menu #toolbarMenu="matMenu">
			<button mat-menu-item routerLink="/organisations">
				<mat-icon>business</mat-icon>
				<span>Organisaties</span>
			</button>
			<button mat-menu-item routerLink="/applications">
				<mat-icon>apps</mat-icon>
				<span>Applicaties</span>
			</button>
			<button mat-menu-item (click)="onLogout()">
				<mat-icon>exit_to_app</mat-icon>
				<span>Uitloggen</span>
			</button>
		</mat-menu>
	</mat-toolbar-row>
</mat-toolbar>
