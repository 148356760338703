import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { environment } from '../environments/environment';

import { AccessToken } from './common/token.model';
import { AppStorage } from "./app.storage";

@Injectable()
export class AuthService {
    private static AccessTokenStorageKey: string = 'AccessToken';

    public userLoggedIn: EventEmitter<boolean> = new EventEmitter<boolean>();

    private jwtHelperService: JwtHelperService;
    private user: User;

    public isLoggedIn: boolean = false;
    public accessToken: string;
    public accessTokenDecoded: AccessToken;

    public userManager: UserManager;

    constructor(private router: Router) {
        this.jwtHelperService = new JwtHelperService();

        const userManagerSettings: UserManagerSettings = {
            authority: environment.identityServerUrl,
            client_id: environment.clientId,
            redirect_uri: environment.appUrl + '/signin-oidc',
            response_type: 'id_token token',
            prompt: 'select_account',
            scope: 'openid profile GreenCommerceConnector',
            post_logout_redirect_uri: environment.appUrl + '/',
        };

        this.userManager = new UserManager(userManagerSettings);
        this.handleAccessToken(AppStorage.get<string>(AuthService.AccessTokenStorageKey));
        this.userManager.getUser().then((user: User) => {
            this.user = user;
        });
    }

    public startAuthentication(): Promise<void> {
        return this.userManager.signinRedirect();
    }

    public completeAuthentication(): Promise<void> {
        return this.userManager.signinRedirectCallback().then((user: Oidc.User) => {
            this.handleUserLogIn(user);
            this.router.navigate(['/organisations']);
        });
    }

    public removeAuthenticatedUser(): Promise<void> {
        return this.userManager.removeUser();
    }

    private handleUserLogIn(user: Oidc.User): void {
        this.user = user;
        this.handleAccessToken(user.access_token);
        this.userLoggedIn.emit(this.isLoggedIn);
    }

    private handleAccessToken(accessToken: string): void {
        if (this.isTokenValid(accessToken))
            this.storeAccessToken(accessToken);
        else
            this.clearAccessToken();
    }

    private isTokenValid(token: string): boolean {
        return token != null && this.jwtHelperService.isTokenExpired(token) == false;
    }

    private storeAccessToken(accessToken: string): void {
        this.accessToken = accessToken;
        this.accessTokenDecoded = this.jwtHelperService.decodeToken(accessToken);
        this.isLoggedIn = true;

        AppStorage.set<string>(AuthService.AccessTokenStorageKey, accessToken);
    }

    public clearAccessToken(): void {
        this.accessToken = null;
        this.accessTokenDecoded = null;
        this.isLoggedIn = false;

        AppStorage.clear(AuthService.AccessTokenStorageKey);
    }
}