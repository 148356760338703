import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth-guard.service';

import { LandingPageComponent } from './layout/landing-page.component';
import { SigninCallbackOidcComponent } from './modules/account/signin-callback-oidc.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', component: LandingPageComponent },

    { path: 'organisations', loadChildren: () => import('./modules/organisations/organisations.module').then((m) => m.OrganisationsModule), canActivate: [AuthGuard] },
    { path: 'applications', loadChildren: () => import('./modules/applications/applications.module').then((m) => m.ApplicationsModule), canActivate: [AuthGuard] },

    { path: 'signin-oidc', component: SigninCallbackOidcComponent },

    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
