import { NgModule } from '@angular/core';

import dayjs from 'dayjs';
dayjs.locale('nl');

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from './shared.module';

import { AccountModule } from './modules/account/account.module';

import { AppComponent } from './app.component';
import { LandingPageComponent } from './layout/landing-page.component';
import { LoaderComponent } from './layout/loader.component';
import { ToolbarComponent } from './layout/toolbar.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    imports: [
        SharedModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        
        HttpClientModule,

        AccountModule,
    ],
    declarations: [
        AppComponent,
        LandingPageComponent,
        LoaderComponent,
        ToolbarComponent,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
