import { NgModule } from '@angular/core';

import { AuthGuard } from './auth-guard.service';
import { AuthService } from './auth.service';
import { NotificationService } from './common/notification.service';
import { LoaderService } from './layout/loader.service';
import { BaseDataContext } from './common/base.datacontext';

import { FormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';

import { ConfirmationDialogComponent } from './layout/confirmation-dialog.component';

@NgModule({
    imports: [
        FormsModule,
        MaterialModule,
    ],
    declarations: [
        ConfirmationDialogComponent,
    ],
    providers: [
        AuthGuard,
        AuthService,
        NotificationService,
        LoaderService,
        BaseDataContext,
    ],
    exports: [
        FormsModule,
        MaterialModule,
    ],
    schemas: [],
})
export class SharedModule { }